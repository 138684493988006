import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { ListGroup } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import { useHistory } from "react-router-dom";
import { AdvertisementBlock } from "../Advertisement/Advertisement";
import { Coffee } from "../CoffeeComp/Coffee";

const sortQuestionsListBasedOnID = (questionList) => {
  return questionList.sort((a, b) => a.id - b.id);
};

const List = (props) => {
  const [questionsAPI, setQuestionsAPI] = useState(
    sortQuestionsListBasedOnID(props.questionsFromAPI)
  );
  let history = useHistory();

  const queClickHandler = (id) => {
    const getQue = props.questionsFromAPI.find((el) => el.id === id);
    props.setQueid(id);
    history.push(`/${id}/${getQue.question.replaceAll(" ", "-")}`);
  };

  const onSearchChangeHandler = (e) => {
    const filteredQue = props.questionsFromAPI.filter((que) =>
      que.question.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setQuestionsAPI(filteredQue);
  };

  const renderListOfQuestions = () => {
    return questionsAPI.map((que) => {
      return (
        <ListGroup.Item onClick={() => queClickHandler(que.id)} key={que.id}>
          {que.id + ". " + que.question}
        </ListGroup.Item>
      );
    });
  };

  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <div
      className="container-fluid"
      style={{ marginTop: "140px", width: "100%" }}
    >
      {/* //Add here Header Ads */}
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <AdvertisementBlock width="100%">
            <ins
              className="adsbygoogle"
              style={{
                display: "flex",
              }}
              data-ad-format="fluid"
              data-ad-layout-key="-fb+5w+4e-db+86"
              data-ad-client="ca-pub-1901795371796130"
              data-ad-slot="9913586565"
              //data-adtest="on"
            ></ins>
          </AdvertisementBlock>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <AdvertisementBlock width="100%">
            <ins
              className="adsbygoogle"
              style={{
                display: "flex",
              }}
              data-ad-client="ca-pub-1901795371796130"
              data-ad-slot="5834740753"
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>
          </AdvertisementBlock>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
          <Card
            style={{
              textAlign: "left",
            }}
          >
            <InputGroup size="lg">
              <InputGroup.Text id="basic-addon1">Searh</InputGroup.Text>
              <Form.Control
                placeholder="Search Question Here...."
                aria-label="questionsearch"
                aria-describedby="basic-addon1"
                onChange={onSearchChangeHandler}
              />
            </InputGroup>
            <Card.Header style={{ marginTop: "40px" }}>
              <h2>ReactJS Questions</h2>
            </Card.Header>
            <ListGroup variant="flush" style={{ cursor: "pointer" }}>
              {renderListOfQuestions()}
            </ListGroup>

            {/* show Footer ads here */}
            <AdvertisementBlock width="100%"></AdvertisementBlock>
          </Card>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <AdvertisementBlock>
            <Card style={{ width: "100%" }}>
              <Card.Img
                variant="top"
                src="https://drive.google.com/uc?export=view&id=1Y-IhKGg0VI2cOJgerGctpOKyOpie-vUA"
              />
              <Card.Body>
                <Card.Text style={{ textAlign: "left" }}>
                  These questions help to crack React Interview. If you are
                  liking it then buy a coffee for me. Cheers..!!
                </Card.Text>
                <Coffee />
              </Card.Body>
            </Card>
          </AdvertisementBlock>

          <AdvertisementBlock>
            <ins
              className="adsbygoogle"
              style={{ display: "flex" }}
              data-ad-client="ca-pub-1901795371796130"
              data-ad-slot="4586643235"
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>
          </AdvertisementBlock>
        </div>
      </div>
      {/*Please dont put any ad here. */}
      <AdvertisementBlock height="10rem" />
    </div>
  );
};

export default List;
