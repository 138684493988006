import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import IMG from "../../assets/favicon.ico";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top" >
        <Navbar.Brand style={{ marginLeft: "20px" }} to="/">
            <img
              src={IMG}
              height="40px"
              width="40px"
              style={{ marginRight: "20px" }}
            />
            <span>TechQue</span>
          {/* </Link> */}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} style={{ marginLeft: "40px" }} to="/">
                  List Of React Questions
             </Nav.Link>
            <NavDropdown title="Topics" id="collasible-nav-dropdown">
              <NavDropdown.Item as="li">
                <Link to="/" style={{ color: "black", textDecoration: "none" }}>
                  ReactJS
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>JavaScript Coming Soon...</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
