export const AdvertisementBlock = (props) => {
  return (
    <div
      style={{
        width: `${props.width}`,
        height: `${props.height}`,
        position: `${props.position}`,
      }}
    >
      {props.description}
      {props.children}
    </div>
  );
};
