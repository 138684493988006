import React, { useEffect, useState } from "react";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { CopyBlock, dracula } from "react-code-blocks";
import { AdvertisementBlock } from "../Advertisement/Advertisement";
import { Coffee } from "../CoffeeComp/Coffee";

const QueCard = (props) => {
  const history = useHistory();
  const [currqueid] = useState(props.queid);
  const [showContent, setShowContent] = useState();
  const backToQueList = () => {
    history.push("/");
  };

  const onBackQue = () => {
    props.getQueOnButtonClick(parseInt(currqueid) - 1);
    setShowContent(false);
  };

  const onNextQue = () => {
    props.getQueOnButtonClick(parseInt(currqueid) + 1);
    setShowContent(false);
  };

  const renderContent = () => {
    return (
      props.questionContent &&
      props.questionContent.map((element, index) => {
        let updatedSectionString = "";
        let updatedCodeStr = "";
        let anotherupdatedCodeStr = "";

        if (element.section) {
          updatedSectionString = element.section.replaceAll("\\n", "\n");
          updatedSectionString = updatedSectionString.replaceAll("\\t", "\t");
        }

        if (element.code) {
          updatedCodeStr = element.code.replaceAll("\\n", "\n");
          anotherupdatedCodeStr = updatedCodeStr.replaceAll("\\t", "\t");
        }

        return (
          <React.Fragment key={index}>
            <ReactMarkdown>{`${updatedSectionString}`}</ReactMarkdown>
            {element.code && (
              <div style={{ marginBottom: "20px" }}>
                <CopyBlock
                  language="jsx"
                  text={anotherupdatedCodeStr}
                  showLineNumbers={true}
                  theme={dracula}
                  wrapLines={true}
                  codeBlock
                />
              </div>
            )}
            {element.imageurl && (
              <Image
                fluid
                src={element.imageurl}
                style={{ marginTop: "20px", marginBottom: "20px" }}
              />
            )}
          </React.Fragment>
        );
      })
    );
  };

  useEffect(() => {
    setShowContent(true);
  }, [currqueid]);

  return (
    <div
      className="container-fluid"
      style={{ marginTop: "140px", width: "100%" }}
    >
      {showContent && (
        <>
          {" "}
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              {/* show ads here of header */}
              <AdvertisementBlock width="100%" />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2">
              {/* show ads here for left side */}
              <AdvertisementBlock width="100%" />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
              <Card
                style={{
                  textAlign: "left",
                }}
              >
                <Card.Header>
                  <h2>{props.queid + ". " + props.headerQue}</h2>
                </Card.Header>
                <Card.Body>{renderContent()}</Card.Body>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexWrap: "wrap",
                    marginBottom: "20px",
                  }}
                >
                  {parseInt(currqueid) !== 1 && (
                    <Button
                      onClick={onBackQue}
                      style={{
                        width: "160px",
                        height: "50px",
                        background: "#2E92C1",
                        borderColor: "#2E92C1",
                      }}
                    >
                      Previous Question
                    </Button>
                  )}
                  <Button
                    onClick={backToQueList}
                    style={{
                      margin: "50px",
                      height: "50px",
                      background: "#2E92C1",
                      borderColor: "#2E92C1",
                    }}
                  >
                    Back To Questions List
                  </Button>
                  {props.numberofQuestions !== parseInt(currqueid) && (
                    <Button
                      onClick={onNextQue}
                      style={{
                        width: "160px",
                        height: "50px",
                        background: "#2E92C1",
                        borderColor: "#2E92C1",
                      }}
                    >
                      Next Question
                    </Button>
                  )}
                </div>
                {/* show Footer ads here */}
                <AdvertisementBlock width="100%"></AdvertisementBlock>
              </Card>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2">
              <AdvertisementBlock>
                <Card style={{ width: "100%" }}>
                  <Card.Img
                    variant="top"
                    src="https://drive.google.com/uc?export=view&id=1Y-IhKGg0VI2cOJgerGctpOKyOpie-vUA"
                    //src="https://drive.google.com/uc?export=view&id=1mFEbM-R4_gf4cvU5mSw3vFCbS_hBjeR1"
                  />

                  <Card.Body>
                    <Card.Text style={{ textAlign: "left" }}>
                      These questions help to crack React Interview. If you are
                      liking it then buy a coffee for me. Cheers..!!
                    </Card.Text>
                    <Coffee />
                  </Card.Body>
                </Card>
              </AdvertisementBlock>
            </div>
          </div>
        </>
      )}
      <AdvertisementBlock height="10rem" />
    </div>
  );
};

export default QueCard;
