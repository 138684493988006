import "./Footer.css";

const Footer = () => {
  return (
    <div id="footer">
      Copyright © 2020-2023 TechQue Inc. <br/>All Rights Reserved Please reach out to on
      this email for any query : techque88@gmail.com
    </div>
  );
};

export default Footer;
