import "./App.css";
import Header from "./Components/Header/Header";
import LeftMenu from "./Components/LeftMenu/LeftMenu";
import Footer from "./Components/Footer/Footer";
import ListContainer from "./Containers/ListContainer";

function App() {
  return (
    <div className="App">
      <Header />
      {/* <LeftMenu /> */}
      <ListContainer />
      <Footer />
    </div>
  );
}

export default App;
