import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import List from "../Components/List/List";
import QueCard from "../Components/QueCard/QueCard";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { useHistory } from "react-router-dom";
import { Loader } from "../Components/Loader/Loader";

const ListContainer = (props) => {
  const [queid, setQueid] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [queContent, setQueContent] = useState([]);
  const [locationKeys, setLocationKeys] = useState([]);
  const [queHead, setQueHead] = useState("");
  const [showLoading, setShowLoading] = useState(true);
  const history = useHistory();

  // useEffect(() => {
  //   (window.adsbygoogle = window.adsbygoogle || []).push({});
  // }, []);

  // Handling Next Back On Question Content Card
  const getQueOnButtonClick = (idque) => {
    setQueid(idque);
    const getQuestion = questions.find((el) => el.id === parseInt(idque));
    setQueContent(getQuestion.ans);
    setQueHead(getQuestion.question);
    history.push(`/${idque}/${getQuestion.question.replaceAll(" ", "-")}`);
  };

  // Helps to Browser Back and Next Button Things based on locationKeys concept
  useEffect(() => {
    history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([...locationKeys, location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys.includes(location.key)) {
          const updatedLocationKeys = locationKeys.filter(
            (locationKey) => locationKey !== location.key
          );
          setLocationKeys(updatedLocationKeys);
          setQueid(getQueidFromUrl());
          setQueContent(getQueContentFromURL().ans);
          setQueHead(getQueContentFromURL().que);
        }
      }
    });
    /*eslint-disable */
  }, [locationKeys]);
  /*eslint-enable */

  // Fetching list of Questions for List
  const sendOnlyQuestionsOnList = (questions) => {
    let questionsList = [];
    for (let i = 0; i < questions.length; i++) {
      questionsList.push({
        id: questions[i].id,
        question: questions[i].question,
      });
    }
    return questionsList;
  };

  // Read Question ID from PathName
  const getQueidFromUrl = () => {
    const urlid = history.location.pathname.split("/");
    return urlid[1];
  };

  // Get the Question and Content from PathName
  const getQueContentFromURL = () => {
    const queObj = {};
    const urlid = getQueidFromUrl();
    const queContent = questions.find(
      (question) => question.id === parseInt(urlid)
    );
    if (queContent) {
      queObj.ans = queContent.ans;
      queObj.que = queContent.question;
      return queObj;
    }
    return "";
  };

  // Used For API call to connect with Firebase
  useEffect(() => {
    const getQues = async () => {
      const noteSnapshot = await getDoc(
        doc(db, "ReactJSQuestionSet", "questionsset")
      );
      if (noteSnapshot.exists()) {
        setQuestions(noteSnapshot.data().reactquestions);
        setShowLoading(false);
      } else {
        console.log("Note doesn't exist");
      }
    };
    getQues();
  }, []);

  // Get Question Content based on Click from List
  const getQuestionContent = (queid) => {
    setQueid(queid);
    const queContent = questions.find((question) => question.id === queid);
    setQueHead(queContent.question);
    setQueContent(queContent.ans);
  };

  if (showLoading) {
    return <Loader />;
  }

  return (
    <Switch>
      <Route
        path="/:id/:que"
        component={() => (
          <QueCard
            queid={queid === 0 ? getQueidFromUrl() : queid}
            numberofQuestions={questions.length}
            questionContent={
              queContent.length === 0 ? getQueContentFromURL().ans : queContent
            }
            getQueOnButtonClick={getQueOnButtonClick}
            headerQue={queHead ? queHead : getQueContentFromURL().que}
          />
        )}
        exact
      ></Route>
      <Route
        path="/"
        component={() => (
          <List
            setQueid={getQuestionContent}
            questionsFromAPI={sendOnlyQuestionsOnList(questions)}
          />
        )}
        exact
      ></Route>
    </Switch>
  );
};

export default ListContainer;
